export class Dropdown {
  value: string;
  label: string;

  constructor(label: string, value: string) {
    this.label = label;
    this.value = value;
  }
}

export class DropdownNumber {
  value: number;
  label: string;

  constructor(label: string, value: number) {
    this.label = label;
    this.value = value;
  }
}

export class ColorDropdown extends Dropdown {
  color: string;

  constructor(label: string, value: string, color: string) {
    super(label, value);
    this.color = color;
  }
}

export class PatientsDropdown extends Dropdown {
  firstname: string;
  lastname: string;

  constructor(label: string, value: string, firstname: string, lastname: string) {
    super(label, value);
    this.firstname = firstname;
    this.lastname = lastname;
  }
}
